export default {
	lpp: {
		title: 'LPP',
		desc: 'Lenspass Portal',
		routes: {
			mgmt: {
				desc: 'Lenspass Management',
				title: 'Management',
			},
		},
	},
};
