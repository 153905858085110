export default {
	lpp: {
		title: 'LPP',
		desc: 'Portal de Lenspass',
		routes: {
			mgmt: {
				desc: 'Gestión de Lenspass',
				title: 'Gestión',
			},
		},
	},
};
